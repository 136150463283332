/* eslint-disable prettier/prettier */
<template>
    <div>
        <SideNav />
        <div class="flex flex-col w-full px-5 xl:px-20 ml-auto prod-area pb-20 md:pb-32">
            <div class="flex top-2-area w-full mx-auto aic jcb mb-3 md:mb-5">
                <div class="flex w-full py-5 border-b relative mt-4 lg:mt-16">
                    <div class="flex aic w-full">
                    <div class="flex flex-col ais jcb top-2-text">
                        <h2 class="b w-full text-gray-800 capitalize fs-18">Einkaufsvorschau</h2>
                        <p class="text-gray-500 w-full fs-12"></p>
                    </div>
                    <div class="absolute bottom-0 right-0 w-90p h-5p bg-primary"></div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col xl:flex-row items-start lg:items-end xl:items-start justify-between lg:mt-5">
                <div class="flex flex-col w-full xl:w-8/12">
                    <div class="flex aic jcb px-2 md:px-4 py-4 bg-gray-200">
                        <h2 class="text-secondary text-base md:text-lg font-medium md:font-bold">Ihre Bestellung</h2>
                        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" class="opacity-70">
                            <path d="M14 19h-14v-1h14v1zm9.247-8.609l-3.247 4.049-3.263-4.062-.737.622 4 5 4-5-.753-.609zm-9.247 2.609h-14v-1h14v1zm0-6h-14v-1h14v1z"/>
                        </svg>
                    </div>

                    <div class="flex flex-col w-full lg:divide-y-2 divide-gray-100 divide-dashed">
                        <div class="flex flex-col aic jcb w-full md:px-4 py-6 md:py-10">
                            <div class="flex aic justify-between lg:justify-start space-x-8 w-full lg:pb-4">
                                <img src="/img/products/brot5.webp" alt="#" class="w-24">
                                <div class="flex flex-col ais jcc">
                                    <h2 class="md:text-lg b text-gray-800">Vollkornbaguette</h2>
                                    <span class="text-primary b text-lg">€ 2.25</span>
                                </div>
                            </div>
                            <div class="flex flex-col lg:flex-row items-end justify-between w-full lg:border-t pt-4 border-gray-200">
                                <div class="flex flex-col ais w-full lg:w-9/12 text-sm pt-4 pb-4 lg:py-0 lg:pb-0 border-t lg:border-t-0 px-2">
                                    <div class="flex aic justify-between lg:justify-start w-full space-x-6">
                                        <span class="text-secondary w-130p">Montag</span>
                                        <span class="text-gray-500 text-sm"><span class="text-secondary mr-3">3</span>stk</span>
                                        <span class="text-secondary ml-3 w-70p text-right">6.75 €</span>
                                    </div>
                                    <div class="flex aic justify-between lg:justify-start w-full space-x-6">
                                        <span class="text-secondary w-130p">Mittwoch</span>
                                        <span class="text-gray-500"><span class="text-secondary mr-3">4</span>stk</span>
                                        <span class="text-secondary ml-3 w-70p text-right">9 €</span>
                                    </div>
                                    <div class="flex aic justify-between lg:justify-start w-full space-x-6">
                                        <span class="text-secondary w-130p">Donnerstag</span>
                                        <span class="text-gray-500"><span class="text-secondary mr-3">1</span>stk</span>
                                        <span class="text-secondary ml-3 w-70p text-right">2.25 €</span>
                                    </div>
                                    <div class="flex aic justify-between lg:justify-start w-full space-x-6">
                                        <span class="text-secondary w-130p">Samstag</span>
                                        <span class="text-gray-500"><span class="text-secondary mr-3">8</span>stk</span>
                                        <span class="text-secondary ml-3 w-70p text-right">18 €</span>
                                    </div>
                                </div>
                                <div class="flex lg:flex-col items-center justify-between lg:items-end lg:justify-end w-full lg:w-44 bg-gray-100 lg:bg-transparent px-2 py-3">
                                    <div class="flex aic justify-end space-x-3">
                                        <span class="text-xs hidden lg:block">Gesamtstückzahl:</span>
                                        <span class="text-sm text-gray-500"><b class="text-secondary text-base lg:text-lg">16</b> stk</span>
                                    </div>
                                    <div class="flex aic justify-end space-x-3">
                                        <span class="text-xs">Gesamtsumme:</span>
                                        <span class="b lg:text-lg text-secondary">36 €</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col aic jcb w-full md:px-4 py-6 md:py-10">
                            <div class="flex aic justify-between lg:justify-start space-x-8 w-full lg:pb-4">
                                <img src="/img/products/brot4.webp" alt="#" class="w-24">
                                <div class="flex flex-col ais jcc">
                                    <h2 class="md:text-lg b text-gray-800">Kastenweißbrot</h2>
                                    <span class="text-primary b text-lg">€ 1.75</span>
                                </div>
                            </div>
                            <div class="flex flex-col lg:flex-row items-end justify-between w-full lg:border-t pt-4 border-gray-200">
                                <div class="flex flex-col ais w-full lg:w-9/12 text-sm pt-4 pb-4 lg:py-0 lg:pb-0 border-t lg:border-t-0 px-2">
                                    <div class="flex aic justify-between lg:justify-start w-full space-x-6">
                                        <span class="text-secondary w-130p">Montag</span>
                                        <span class="text-gray-500 text-sm"><span class="text-secondary mr-3">3</span>stk</span>
                                        <span class="text-secondary ml-3 w-70p text-right">5.25 €</span>
                                    </div>
                                    <div class="flex aic justify-between lg:justify-start w-full space-x-6">
                                        <span class="text-secondary w-130p">Dienstag</span>
                                        <span class="text-gray-500"><span class="text-secondary mr-3">4</span>stk</span>
                                        <span class="text-secondary ml-3 w-70p text-right">7 €</span>
                                    </div>
                                    <div class="flex aic justify-between lg:justify-start w-full space-x-6">
                                        <span class="text-secondary w-130p">Mittwoch</span>
                                        <span class="text-gray-500"><span class="text-secondary mr-3">1</span>stk</span>
                                        <span class="text-secondary ml-3 w-70p text-right">1.75 €</span>
                                    </div>
                                    <div class="flex aic justify-between lg:justify-start w-full space-x-6">
                                        <span class="text-secondary w-130p">Donnerstag</span>
                                        <span class="text-gray-500"><span class="text-secondary mr-3">8</span>stk</span>
                                        <span class="text-secondary ml-3 w-70p text-right">14 €</span>
                                    </div>
                                    <div class="flex aic justify-between lg:justify-start w-full space-x-6">
                                        <span class="text-secondary w-130p">Freitag</span>
                                        <span class="text-gray-500"><span class="text-secondary mr-3">7</span>stk</span>
                                        <span class="text-secondary ml-3 w-70p text-right">12.25 €</span>
                                    </div>
                                    <div class="flex aic justify-between lg:justify-start w-full space-x-6">
                                        <span class="text-secondary w-130p">Samstag</span>
                                        <span class="text-gray-500"><span class="text-secondary mr-3">3</span>stk</span>
                                        <span class="text-secondary ml-3 w-70p text-right">5.25 €</span>
                                    </div>
                                    <div class="flex aic justify-between lg:justify-start w-full space-x-6">
                                        <span class="text-secondary w-130p">Sonntag</span>
                                        <span class="text-gray-500"><span class="text-secondary mr-3">5</span>stk</span>
                                        <span class="text-secondary ml-3 w-70p text-right">8.75 €</span>
                                    </div>
                                </div>
                                <div class="flex lg:flex-col items-center justify-between lg:items-end lg:justify-end w-full lg:w-44 bg-gray-100 lg:bg-transparent px-2 py-3">
                                    <div class="flex aic justify-end space-x-3">
                                        <span class="text-xs hidden lg:block">Gesamtstückzahl:</span>
                                        <span class="text-sm text-gray-500"><b class="text-secondary text-base lg:text-lg">31</b> stk</span>
                                    </div>
                                    <div class="flex aic justify-end space-x-3">
                                        <span class="text-xs">Gesamtsumme:</span>
                                        <span class="b lg:text-lg text-secondary">57 €</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col aic jcb w-full md:px-4 py-6 md:py-10">
                            <div class="flex aic justify-between lg:justify-start space-x-8 w-full lg:pb-4">
                                <img src="/img/products/brot3.webp" alt="#" class="w-24">
                                <div class="flex flex-col ais jcc">
                                    <h2 class="md:text-lg b text-gray-800">Roggenbrot</h2>
                                    <span class="text-primary b text-lg">€ 2.75</span>
                                </div>
                            </div>
                            <div class="flex flex-col lg:flex-row items-end justify-between w-full lg:border-t pt-4 border-gray-200">
                                <div class="flex flex-col ais w-full lg:w-9/12 text-sm pt-4 pb-4 lg:py-0 lg:pb-0 border-t lg:border-t-0 px-2">
                                    <div class="flex aic justify-between lg:justify-start w-full space-x-6">
                                        <span class="text-secondary w-130p">Donnerstag</span>
                                        <span class="text-gray-500"><span class="text-secondary mr-3">8</span>stk</span>
                                        <span class="text-secondary ml-3 w-70p text-right">14 €</span>
                                    </div>
                                    <div class="flex aic justify-between lg:justify-start w-full space-x-6">
                                        <span class="text-secondary w-130p">Sonntag</span>
                                        <span class="text-gray-500"><span class="text-secondary mr-3">5</span>stk</span>
                                        <span class="text-secondary ml-3 w-70p text-right">8.75 €</span>
                                    </div>
                                </div>
                                <div class="flex lg:flex-col items-center justify-between lg:items-end lg:justify-end w-full lg:w-44 bg-gray-100 lg:bg-transparent px-2 py-3">
                                    <div class="flex aic justify-end space-x-3">
                                        <span class="text-xs hidden lg:block">Gesamtstückzahl:</span>
                                        <span class="text-sm text-gray-500"><b class="text-secondary text-base lg:text-lg">13</b> stk</span>
                                    </div>
                                    <div class="flex aic justify-end space-x-3">
                                        <span class="text-xs">Gesamtsumme:</span>
                                        <span class="b lg:text-lg text-secondary">22.75 €</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="flex flex-col w-full lg:w-1/2 xl:w-3/12 mt-16 xl:mt-0">
                    <div class="flex aic jcb px-2 md:px-4 py-4 bg-gray-200 mb-8">
                        <h2 class="text-secondary text-base md:text-lg font-medium md:font-bold">Summary</h2>
                        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" class="opacity-40" fill-rule="evenodd" clip-rule="evenodd">
                            <path d="M10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591zm-5.041-15c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z"/>                        
                        </svg>
                    </div>
                    <div class="flex flex-col w-full space-y-2 text-sm">
                        <span class="flex aic jcb space-x-2 w-full">
                            <span>Startdatum</span>
                            <span class="text-gray-700 b text-base">Nov.10.2021</span>
                        </span>
                        <span class="flex aic jcb space-x-2 w-full">
                            <span>Gesamtstückzahl</span>
                            <span class="text-gray-700"><b class="text-base mr-2">16</b> Stück</span>
                        </span>
                        <span class="flex aic jcb space-x-2 w-full">
                            <span>Gesamtsumme</span>
                            <span class="text-primary fs-20 b">148 €</span>
                        </span>
                    </div>

                    <div class="flex flex-col aic jcb w-full mt-16 space-y-6">
                        <router-link to="#" class="btn-secondary w-full px-16 h-60p rounded-lg fcc whitespace-nowrap">
                            Kostenpflichtig bestellen
                        </router-link>
                        <!-- <router-link to="#" class="btn-gray w-full px-16 h-60p rounded-lg fcc">
                            Weiter einkaufen
                        </router-link> -->
                    </div>
                </div>

            </div>
        </div>
        <ScheduleEdit
            :showEditModal="showEditModal"
            @close="showEditModal = false"
        ></ScheduleEdit>

    </div>
</template>
<script>
import SideNav from "@/components/SideNav.vue";
import ScheduleEdit from "@/components/ScheduleEdit.vue";


export default {
  components: { SideNav, ScheduleEdit },
  data() {
    return {
        showEditModal: false,
    };
  },
};
</script>
